export var tagColor={
  list:[{
    color:"#199BF1",
    type:1
  },{
    color:"#FC8C16",
    type:2
  },{
    color:"#6A71B7",
    type:3
  },{
    color:"#019C96",
    type:4
  },{
    color:"#16AE30",
    type:5
  },{
    color:"#E5230F",
    type:6
  }]
}
